import React from 'react';
import { Box, Chip, Card, CardContent, Grid } from "@mui/material";

import { Loading, useDataProvider } from 'react-admin';
import moment from 'moment';
import CountryReportChart from "./countryReport";
import { AnyMxRecord } from 'dns';




const CountryChart = (props : any) => {

console.log(props);
    if (props.history?.action == "PUSH") {
        window.location.reload();
    }


    const dataProvider = useDataProvider();

    const [data, setData] = React.useState([]);
    React.useEffect(() => {
      if (data?.length == 0)
        dataProvider.CountryReport().then((newData : any) => {
  
 
          setData(newData.data.filter((x:any)=> x.name!="Turkey"));
        });
    }, []);

    
    


    return    <Card style={{ marginTop: "1em", padding: 0 }} >
    <CardContent style={{ padding: 0 }} >
        {data && data.length>0 &&
        <CountryReportChart data={data} i={moment().format()} /> 
        }
             
             {data && data.length>0 &&
             <div>
 <Grid container spacing={5} style={{margin:"3em", paddingRight:"5em"}}>
    {data.map((x:any)=> {


return <React.Fragment key={"___Chart___" + x.name}>
<Grid item xs={2}>
          {x?.name}
          </Grid>
          <Grid item xs={1}>
          <Chip color="primary" label={x?.count} />
          </Grid>
        
   
</React.Fragment>

    })}
          </Grid>
             </div>
       
        }
      
    </CardContent>
</Card>
}

export default CountryChart;