import React, { useEffect, useState } from "react";
import {
  Title, Create,
  Form,
  TextInput,
  SaveButton,
  useEditContext,
  required,
  useDataProvider,
  useNotify,
  DateInput,
} from "react-admin";
import { Grid, Card, Typography, CardContent, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";

const CouponCreate = (props: any) => {




  const [OperationType, setOperationType] = useState<any>("Cari");
  const [StartDate, setStartDate] = useState<any>("");
  const [EndDate, setEndDate] = useState<any>("");
  const [DiscountType, setDiscountType] = useState<any>("Rate");
  const [UseType, setUseType] = useState<any>("Special");
  const [MaxUse, setMaxUse] = useState<any>("");
  const [CampaignName, setCampaignName] = useState<any>("");
  const [Code, setCode] = useState<any>("");
  const [RateAmount, setRateAmount] = useState<any>("");
  const [locations, setLocations] = React.useState([]);
  const [locationCode, setLocationCode] = useState("");
  const [GSMEmail, setGSMEmail] = useState("")

  const dataProvider = useDataProvider();
  const notify = useNotify();

  React.useEffect(() => {
    (async () => {

      const res = await dataProvider.getLocations();;


      setLocations(
        res?.data
      );


    })()
  }, [])
  

  //   useEffect(() => {
  //     setGsmNumber(props.gsmNumbers);
  //   }, [])

  const SaveComp = () => {



    const saveCoupon = () => {

      if (!StartDate) {
        alert("StartDate must be filled.")
        return
      }

      if (!EndDate) {
        alert("EndDate must be filled.")
        return
      }
      if (!MaxUse) {
        alert("MaxUse must be filled.")
        return
      }

      if (!CampaignName) {
        alert("CampaignName must be filled.")
        return
      }

      if (!RateAmount) {

        alert("RateAmount must be filled.")
        return
      }

      if (!Code) {

        alert("Code must be filled.")
        return
      }


      if (!locationCode) {

        alert("Location must be filled.")
        return
      }

      dataProvider
        .CreateCampaign(
          JSON.stringify({
            OperationType: OperationType,
            StartDate: StartDate,
            EndDate: EndDate,
            DiscountType: DiscountType,
            UseType: UseType,
            MaxUse: MaxUse,
            UseCount: 0,
            CampaignName: CampaignName,
            Code: Code,
            RateAmount: RateAmount,
            Status: false,
            LocationCode : locationCode,
            GSMEmail : GSMEmail + ""
          })
        )
        .then(({ data }: any) => {
          notify(`Save successfully`, { type: "success", undoable: false });
          window.location.href = "/#/campaigns";
        });
    };
    return <Button variant="contained" onClick={() => saveCoupon()}>Save</Button>
  };

  return (
    <Card style={{ marginTop: "1em" }} >
      <Title title={"Create Coupon"} />
      <CardContent>
        <Form mode="onBlur" reValidateMode="onBlur">
          <Typography variant="h4" gutterBottom>
            Create Coupon
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={2} style={{ paddingRight: "1em" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">OperationType</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="OperationType"
                  value={OperationType}

                  onChange={(e) =>setOperationType(e?.target?.value)}
                >
                  <MenuItem selected value={"Cari"}>Cari</MenuItem>
                  <MenuItem value={"Ücretsiz"}>Ücretsiz</MenuItem>
                </Select></FormControl>


            </Grid>
            <Grid item xs={4} style={{ paddingRight: "1em" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="OperationType"
                  value={locationCode}

                  onChange={(e:any) =>setLocationCode(e?.target?.value)}
                >
                  {locations?.map((x : any)=>{
return <MenuItem selected value={x?.locationCode}>{x?.name}</MenuItem>

                  })}
                </Select></FormControl>


            </Grid>
            <Grid item xs={6} style={{ paddingRight: "1em" }}>
              <TextInput
                fullWidth
                source="CampaignName"
                label="CampaignName"
                validate={required()}
                defaultValue={CampaignName}
                onChange={(v) => setCampaignName(v?.target ? v?.target?.value : v)}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingRight: "1em" }}>
              <DateInput fullWidth source={"StartDate"} validate={required()} onChange={(v) => setStartDate(v?.target ? v?.target?.value : v)} />


            </Grid>
            <Grid item xs={6} style={{ paddingRight: "1em" }}>
              <DateInput fullWidth source={"EndDate"} validate={required()} onChange={(v) => setEndDate(v?.target ? v?.target?.value : v)} />


            </Grid>
            <Grid item xs={3} style={{ paddingRight: "1em" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">DiscountType</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="DiscountType"
                  value={DiscountType}

                  onChange={(e)=> setDiscountType(e?.target?.value)}
                >
                  <MenuItem selected value={"Rate"}>Rate</MenuItem>
                  <MenuItem value={"Amount"}>Amount</MenuItem>
                </Select></FormControl>


            </Grid>
            <Grid item xs={3} style={{ paddingRight: "1em" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">UseType</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="UseType"
                  value={UseType}

                  onChange={(e)=> setUseType(e?.target?.value)}
                >
                  <MenuItem selected value={"Special"}>Special</MenuItem>
                  <MenuItem value={"Multiple"}>Multiple</MenuItem>
                </Select></FormControl>


            </Grid>
            <Grid item xs={6} style={{ paddingRight: "1em" }}>
              <FormControl fullWidth>
                <TextInput
                  fullWidth
                  source="GSMEmail"
                  label="GSM / Email"
                  defaultValue={GSMEmail}
                  onChange={(v) => setGSMEmail(v?.target ? v?.target?.value : v)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3} style={{ paddingRight: "1em" }}>
              <FormControl fullWidth>
                <TextInput
                  fullWidth
                  source="RateAmount"
                  label="Rate / Amount"
                  type="number"
                  validate={required()}
                  defaultValue={RateAmount}
                  onChange={(v) => setRateAmount(v?.target ? v?.target?.value : v)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3} style={{ paddingRight: "1em" }}>
              <FormControl fullWidth>
                <TextInput
                  fullWidth
                  source="MaxUse"
                  label="MaxUse"
                  type="number"
                  validate={required()}
                  defaultValue={MaxUse}
                  onChange={(v) => setMaxUse(v?.target ? v?.target?.value : v)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} style={{ paddingRight: "1em" }}>
              <FormControl fullWidth>
                <TextInput
                  fullWidth
                  source="Code"
                  label="Code"
                  validate={required()}
                  defaultValue={Code}
                  onChange={(v) => setCode(v?.target ? v?.target?.value : v)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}></Grid>
            <Grid item xs={12}>
              <SaveComp />
            </Grid>
          </Grid>
        </Form>
      </CardContent>
    </Card>
  );
};
export default CouponCreate;
