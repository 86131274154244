import React, { useEffect, useState } from "react";
import {
    Title,  Create,
  Form,
  TextInput,
  SaveButton,
  useEditContext,
  required,
  useDataProvider,
  useNotify,
} from "react-admin";
import { Grid, Card, Typography, CardContent, Button } from "@mui/material";

const SendSms = (props: any) => {
  let { gsmNumbers, message } = getQueryParams();

  if(gsmNumbers) gsmNumbers = atob(gsmNumbers);
  if(!gsmNumbers) gsmNumbers = "905";
  

  const [gsmNumber, setGsmNumber] = useState<any>(
    gsmNumbers ? gsmNumbers : "905"
  );
  const [messageText, setMessage] = useState<any>(message);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  //   useEffect(() => {
  //     setGsmNumber(props.gsmNumbers);
  //   }, [])

  const SaveComp = () => {
    const postMessages = () => {
      if (gsmNumber == "" || gsmNumber == "905" || messageText == "") return;

      console.log({
        phoneNumber: gsmNumber,
        message: messageText + "",
      });
      dataProvider
        .SendSms(
          JSON.stringify({
            phoneNumber: gsmNumber,
            message: messageText + "",
            validationCode: "EasyPointSmsSender"
          })
        )
        .then(({ data }: any) => {
          notify(`Sms send successfully`, { type: "success", undoable: false });
        });
    };
    return <Button variant="contained"  onClick={() => postMessages()}>Post</Button>
  };

  return (
    <Card style={{ marginTop: "1em" }} >
        <Title title={"SMS"} />
      <CardContent>
        <Form>
          <Typography variant="h4" gutterBottom>
            Send SMS
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={6} style={{ paddingRight: "1em", display: (localStorage.getItem('username') == "desk" && gsmNumbers!="905") ? 'none': ''  }}>
              <TextInput
                fullWidth
                source="gsmNumber"
                label="Gsm Number"
                placeholder="905XXXXXXXXX"
                validate={required()}
                defaultValue={gsmNumbers}
                onChange={(v) => setGsmNumber(v?.target ? v?.target?.value : v)}
              />
            </Grid>
            <Grid item xs={6}style={{ display: (localStorage.getItem('username') == "desk" && gsmNumbers!="905") ? 'none': ''  }}></Grid>
            <Grid item xs={6}>
              <TextInput
                multiline={true}
                fullWidth
                defaultValue={message}
                rows={5}
                source="message"
                validate={required()}
                onChange={(v) => setMessage(v?.target ? v?.target?.value : v)}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12}>
              <SaveComp />
            </Grid>
          </Grid>
        </Form>
      </CardContent>
    </Card>
  );
};
export default SendSms;

const getQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.href);
  return {
    gsmNumbers: urlParams.get("gsmNumbers"),
    message: urlParams.get("message"),
  };
};
