import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslate } from "react-admin";

import HorizontalLinearStepper from "../components/HorizontalLinearStepper";
import Card from "@mui/material/Card";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import NumbersIcon from "@mui/icons-material/Numbers";
import { useLocaleState } from "react-admin";

const getQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.href);
  return {
    languageCode: urlParams.get("languageCode"),
  };
};

const CheckIn = () => {
  const [data, setData] = useState("No result");
  const [value, setValue] = React.useState(0);
  const translate = useTranslate();
  const [locale, setLocale] = useLocaleState();
  const { languageCode } = getQueryParams();

  React.useEffect(() => {
    setLocale(languageCode ? languageCode : "tr");
  }, [languageCode]);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
     

      <Paper
        elevation={1}
        style={{
          padding: "1em",
          paddingRight: "0",
          marginRight: "5px",
          marginTop:"1em"
        }}
      >
        <HorizontalLinearStepper
          inputType={value == 1 ? "qr" : isSelectOrText(value)}
          setValue={setValue}
        />
      </Paper>
    </React.Fragment>
  );
};

export default CheckIn;

function isSelectOrText(value) {
  return value == 0 ? "select" : "text";
}


