import React, { useEffect, useState } from "react";
import {
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  ListBase,
  FunctionField,
  Title,
  useDataProvider,
  Button,
  Loading,
  TopToolbar,
  ChipField,
  Link,
  ListToolbar,
  Pagination,
  BooleanField,
  DeleteButton,
  useNotify,
} from "react-admin";
import { Breadcrumbs, Card, Typography, Link as Link2, CardContent } from "@mui/material";
import Chip from "@mui/material/Chip";
import moment from "moment";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import NumbersIcon from "@mui/icons-material/Numbers";
import CreateIcon from "@mui/icons-material/Create";
import ExportIcon from "@mui/icons-material/Download";

import { utils, writeFileXLSX } from "xlsx";
import { MarginTwoTone } from "@mui/icons-material";
import * as XLSX from 'xlsx';

function FileInput() {
  const [data, setData] = React.useState<any>(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event?.target?.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      setData(sheetData);

      console.log("sheetData", JSON.stringify(sheetData))
    };

    reader.readAsBinaryString(file);
  };

  const bulkInsert = () => {

    const newData = 
    data.map((item: any) => {

      
     item.status = false;
     item.useCount = 0;
     return {
      "operationType": item?.operationType,
      "startDate": moment(item?.startDate)?.format("YYYY-MM-DD"),
      "endDate": moment(item?.endDate)?.format("YYYY-MM-DD"),
      "discountType": item?.discountType,
      "useType": item?.useType,
      "maxUse":  item?.maxUse,
      "useCount": 0,
      "campaignName": item?.campaignName,
      "code":  item?.code + "",
      "status": false,
      "rateAmount":  item?.rateAmount,
      "locationCode" : item?.locationCode,
      "gsmEmail": item?.gsmEmail + ""
    };
 });
  
    dataProvider.BulkInsert(newData).then(() => {

      notify(`Import successfully`, { type: "success", undoable: false });
      window.location.href = "/#/campaigns";
    })
    
  }

  return (
    <React.Fragment>
      <div role="presentation" style={{ marginTop: '1em'}}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link2 underline="hover" color="inherit" href="/">
            EasyPoint
          </Link2>
          <Link2
            underline="hover"
            color="inherit"
            href="/material-ui/getting-started/installation/"
          >
            Home
          </Link2>
          <Typography color="text.primary">Coupons Import</Typography>
        </Breadcrumbs>
      </div>
      <Card style={{padding:'3em', marginTop:'1em'}}>
        <CardContent>

          <div>
          Download Sample Import File : <Link2 href="https://box.easypoint.com.tr/sampleImportFile.xlsx" target="_blank">SampleImportFile.xls</Link2><br /><br />

            <input type="file" onChange={handleFileUpload} />
            {data && (
              <div><br /><br />
               <Button onClick={()=> bulkInsert()} variant="contained" label="SAVE ALL COUPONS" /><br /><br />
                <h2>Imported Data:</h2>
                <pre>{JSON.stringify(data, null, 2)}</pre>
              </div>
            )}
          </div>

        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default FileInput;