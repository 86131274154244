import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5xy from "@amcharts/amcharts5/xy";

let root = null;

export default class CountryReportChart extends Component {

  constructor(props) {
    super(props);
    root = null;
  }

  componentDidMount() {

    
/* Chart code */
// Create root element
// https://www.amcharts.com/docs/v5/getting-started/#Root_element
if(root==null)
root = am5.Root.new("chartdiv");


// Set themes
// https://www.amcharts.com/docs/v5/concepts/themes/
root.setThemes([
  am5themes_Animated.new(root)
]);


// Create chart
// https://www.amcharts.com/docs/v5/charts/xy-chart/
let chart = root.container.children.push(am5xy.XYChart.new(root, {
  panX: true,
  panY: true,
  wheelX: "panX",
  wheelY: "zoomX",
  pinchZoomX: true,
  paddingTop: 50
}));

chart.children.push(am5.Label.new(root, {
  text: "PHONE CODE REPORT",
  fontSize: 20,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "400",
  fill: 'rgba(0, 0, 0, 0.54)',
  x: am5.p50,
  centerX: am5.p50,
  y: 0
}));
// Add cursor
// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
cursor.lineY.set("visible", false);


// Create axes
// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
xRenderer.labels.template.setAll({
  rotation: -90,
  centerY: am5.p50,
  centerX: am5.p100,
  paddingRight: 15
});

xRenderer.grid.template.setAll({
  location: 1
})

let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
  maxDeviation: 0.3,
  categoryField: "name",
  renderer: xRenderer,
  tooltip: am5.Tooltip.new(root, {})
}));

let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
  maxDeviation: 0.3,
  renderer: am5xy.AxisRendererY.new(root, {
    strokeOpacity: 0.1
  })
}));


// Create series
// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
let series = chart.series.push(am5xy.ColumnSeries.new(root, {
  name: "Series 1",
  xAxis: xAxis,
  yAxis: yAxis,
  valueYField: "count",
  sequencedInterpolation: true,
  categoryXField: "name",
  tooltip: am5.Tooltip.new(root, {
    labelText: "{valueY}"
  })
}));

series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
series.columns.template.adapters.add("fill", function(fill, target) {
  return chart.get("colors").getIndex(series.columns.indexOf(target));
});

series.columns.template.adapters.add("stroke", function(stroke, target) {
  return chart.get("colors").getIndex(series.columns.indexOf(target));
});


am5exporting.Exporting.new(root, {
  menu: am5exporting.ExportingMenu.new(root, {})
});

// Set data
let data = this.props.data;
// [{
//   country: "USA",
//   value: 2025
// }, {
//   country: "China",
//   value: 1882
// }, {
//   country: "Japan",
//   value: 1809
// }, {
//   country: "Germany",
//   value: 1322
// }, {
//   country: "UK",
//   value: 1122
// }, {
//   country: "France",
//   value: 1114
// }, {
//   country: "India",
//   value: 984
// }, {
//   country: "Spain",
//   value: 711
// }, {
//   country: "Netherlands",
//   value: 665
// }, {
//   country: "South Korea",
//   value: 443
// }, {
//   country: "Canada",
//   value: 441
// }];

xAxis.data.setAll(data);
series.data.setAll(data);


// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/
series.appear(1000);
chart.appear(1000, 100);

  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div style={{ padding: '2em' }}>
        <div
          id={`chartdiv`}
          style={{ width: "100%", height: "550px", fontSize: "0.7em" }}
        />
      </div>
    );
  }
}


