import * as React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import InventoryIcon from "@mui/icons-material/Inventory";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
// import { useAppDataProvider } from "../dataProvider";
import { CircularProgress } from "@mui/material";
import { useDataProvider, useTranslate } from "react-admin";

const getQueryParams = (props: any) => {
  const urlParams = new URLSearchParams(window.location.href);
  return {
    locationCode: urlParams.get("locationCode"),
  };
};

export default function ControlledAccordions(props: any) {
  const [locationId, setLocationID] = React.useState<any>(false);


  const { locationCode }: any = getQueryParams(props);
  const translate = useTranslate();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const [kutuId, setKutuId] = React.useState<any>("");
  const [locations, setLocations] = React.useState<any>(false);
  const dp = useDataProvider();

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      props.setLocationCode(locationId);
    };

  const kutuIdHandle = (v: string) => {
    setKutuId(v);
  };


  
  React.useEffect(() => {
 
    setLocationID(locationCode);
    props.setLocationCode(locationCode);
   
  }, [ locationCode]);

  
  React.useEffect(() => {
    (async () => {
      const res: any = await dp.getLocations();
      if (res) {
 
        if (
          locationCode &&
          res.data?.filter((k: any) => k?.locationCode == locationCode).length > 0
        ) {
          setLocations(
            res.data.filter((t: any) => t?.locationCode == locationCode)
          );
          setLocationID(locationCode);
          props.setLocationCode(locationCode);
        } else {

          let newData =res.data;
          if(localStorage.getItem('username') == "natavega")
          newData = newData.filter((x:any)=> x.locationCode == "AA104")

          setLocations(newData);
        }
      }
    })();
   
  }, [dp]);

  let lastLocation = "";

  return (
    <div style={{ marginTop: "1em" }}>
      {!locations && (
        <div
          style={{
            width: "50px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {locations?.length == 0 && <span>Lokasyon Bulunamadı</span>}

    
      {locations &&
        (!locationId || locations.length > 1) &&
        locations?.sort(function (a: any, b: any) {
          if (a.locationCode < b.locationCode) {
            return -1;
          }
          if (a.locationCode > b.locationCode) {
            return 1;
          }
          return 0;
        })?.filter((c: any) => c.locationCode != "EP2001")?.map((location: any, index: number) => {

          if (lastLocation == location.locationCode)
            return;

          lastLocation = location.locationCode;
          return (
            <React.Fragment  key={"__accor__" + index}>
              {index == 0 && (
                <Grid container spacing={0} style={{ maxWidth: "180px" }}>
                  <Grid item xs={2}>
                    <KeyboardArrowDownIcon
                      style={{ marginTop: "20px", float: "left" }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                    Choose Location
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Accordion
                 
                expanded={expanded === "panel" + index}
                onChange={handleChange("panel" + index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={"panel" + index + "bh-content"}
                  id={"panel" + index + "bh-header"}
                >
                  <Typography sx={{ width: "65%", flexShrink: 0 }}>
                  {location?.locationCode == "AA102" && <span>Galataport Easy Point, D blok Zemin Kat</span>}
                    {location?.locationCode == "AA103" && <span>Galataport, D blok P1 Katı</span>}
                    {!["AA102", "AA103"].includes(location?.locationCode) && <span>{location?.name}</span>}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    {location?.districtName && location?.districtName + ","}{" "}
                    {location?.cityName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    border: "solid 1px #e8e8e8",
                    padding: "1em",
                    margin: "1em",
                  }}
                >
                  {expanded === "panel" + index && (
                    <LocationDetail
                      kutuId={kutuId}
                      kutuIdHandle={kutuIdHandle}
                      location={location}
                      dp={dp}
                      {...props}
                      
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          );
        })}

      {locations.length == 1 && locationId && (
        <LocationDetail
          kutuId={kutuId}
          kutuIdHandle={kutuIdHandle}
          location={locations[0]}
          dp={dp}
          {...props}
        />
      )}

    </div>
  );
}
const LocationDetail = (props: any) => {
  const [lockers, setLockers] = React.useState<any>(false);
  const translate = useTranslate();
  React.useEffect(() => {
    (async () => {
      props.setLocationCode(props?.location?.locationCode);
      let res = await props?.dp?.getLockers(props?.location?.locationCode);
      if (res) {
        setLockers(res.data);
      }
    })();
  }, [props?.dp, props?.location?.locationCode]);

  return (
    <React.Fragment>
      {props?.location?.types?.map((item: any, index: number) => {
        return (
          <React.Fragment key={"__boxAlert__" + index}>
            <Alert severity="warning" icon={<InventoryIcon />}>
              <AlertTitle>
                <strong> {getKutuType(item?.code)}</strong> {item?.code}{" "}
                <small>
                  ({item?.width}X{item?.height}X{item?.depth} cm)
                </small>
              </AlertTitle>
              Availability
              <strong>
                {item?.availableCount}/{item?.totalCount}
              </strong>
            </Alert>

            <FormControl style={{ padding: "1em" }}>
              <FormLabel id="demo-radio-buttons-group-label">
              Available Lockers
              </FormLabel>

              {!lockers && (
                <div
                  style={{
                    width: "50px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <CircularProgress />
                </div>
              )}

              {lockers?.length == 0 && <span>Kutu Bulunamadı</span>}

              {lockers && lockers?.length > 0 && (
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={props?.kutuId}
                  name="radio-buttons-group"
                  onChange={(_e, v) => props?.kutuIdHandle(v)}
                >
                  {lockers
                    .filter((x: any) => x.typeCode == item?.code)
                    ?.sort((a: any, b: any) => a.lockerNumber - b.lockerNumber)
                    .map((locker: any, i: number) => {
                      return (
                        <FormControlLabel
                          key={"___locker___" + i}
                          value={locker?.qrCode}
                          control={<Radio />}
                          label={locker?.lockerNumber}
                          disabled={!locker?.isAvailable}
                        />
                      );
                    })}
                </RadioGroup>
              )}
            </FormControl>
          </React.Fragment>
        );
      })}

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          color="primary"
          variant="contained"
          onClick={() => props?.jumpBoxNumber(props?.kutuId)}
          disabled={!props?.kutuId || props?.kutuId == ""}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
};

const getKutuType = (typeCode: string) => {
  const box = boxes?.find((x) => x.key == typeCode);
  if (box) return box.value;
  return typeCode;
};

const boxes = [
  { key: "S", value: "Small" },
  { key: "M", value: "Medium" },
  { key: "L", value: "Large" },
  { key: "XL", value: "X Large" },
];
