import React, { } from "react";
import { Create, Edit, Form, SimpleForm, Button, Title, useDataProvider, useNotify, } from "react-admin";
import { Grid, Typography } from "@mui/material";
import { deepOrange } from '@mui/material/colors';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import InventoryIcon from "@mui/icons-material/Inventory";
import { CircularProgress, Avatar, Card, CardContent, TextField } from "@mui/material";
import { useTranslate } from "react-admin";
import { getAllJSDocTags } from "typescript";

const LockerPrice = (props: any) => {

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const SaveComp = () => {
    const postMessages = () => {

      if (data.length > 0)
        dataProvider
          .UpdateBoxPrice(
            data?.map((x: any) => {

              return { time: x.time, type: x.type, price: x.price }
            })
          )
          .then(({ data }: any) => {
            notify(`Price save successfully`, { type: "success", undoable: false });
          });
    };
    return <Button variant="contained" color="primary" size="large" disabled={false} label={"SAVE"} onClick={() => postMessages()} />
  };



  const [data, setData] = React.useState<any>([]);


  React.useEffect(() => {
    (async () => {
      await dataProvider.getBoxPrice().then((res: any) => {

        setData(res?.data?.data?.map((x: any) => {

          return { time: x.time, type: x.type, price: x.price }
        }));
      })

    })();
  }, [dataProvider])


  const priceHandleChange = (time: number, type: any, price: any) => {

    let newPrice = parseDecimal(price);


    const newData = data?.length > 0 ? [...data?.filter((x: any) => !(x.time == time && x.type == type)), { time: time, type: type, price: newPrice }] : [{ time: time, type: type, price: newPrice }];
    setData(newData);

  }

  const parseDecimal = (v: any) => {
    v = v && v.replaceAll(',', '.').replaceAll('..', '.').replace(/[^0-9.]/g, '');
    if (v && (' ' + v).indexOf('.') > 0 && v.split('.').length > 2) v = v.split('.')[0];
    return v;
  };


  const getData = (datas: any, time: number, type: string) => {
    return datas?.filter((k: any) => k.time === time && k.type === type)?.length > 0 ? data?.filter((k: any) => k.time === time && k.type === type)[0]?.price : 0;
  }


  return (
    <div style={{ marginTop: "1em" }}>
      <Card style={{ marginTop: "1em" }} >
        <Title title={"Locker Price"} />

        <CardContent style={{ padding: "3em" }}>
          <Form >
            <Typography variant="h4" gutterBottom >
              Locker Price
            </Typography>
            <div style={{ width: "500px", marginTop: "2em" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>SMALL</Grid><Grid xs={1}></Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>LARGE</Grid><Grid xs={1}></Grid>
                {[1, 2, 4, 6].map((x: number, i) => (<React.Fragment>
                  <Grid item xs={2} ><div style={{ marginTop: "1.3em", fontWeight: "bold", float: "right" }}>{x} HOURS</div></Grid>
                  <Grid item xs={4} ><TextField value={getData(data, x, "k")} onChange={(e: any) => priceHandleChange(x, "k", e?.target?.value)} label="" /></Grid><Grid item xs={1}><div style={{ paddingTop: "1.5em" }}>TL</div></Grid>
                  <Grid item xs={4}><TextField value={getData(data, x, "b")} onChange={(e: any) => priceHandleChange(x, "b", e?.target?.value)} label="" /></Grid><Grid item xs={1}><div style={{ paddingTop: "1.5em" }}>TL</div></Grid>

                </React.Fragment>))}
                <Grid item xs={12}>
                  <SaveComp />
                </Grid>
              </Grid>
            </div>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};


export default LockerPrice;
