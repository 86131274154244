import {
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  ListBase,
  useDataProvider,
  defaultExporter,
  Button,
  Loading,
  TopToolbar,
  ChipField,
  TextInput,
  Link,
  FunctionField,
  ListToolbar,
  Pagination,
  Title,
} from "react-admin";
import { Card } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useListContext } from "react-admin";

import React, { useEffect, useState } from "react";
import moment from "moment";
import { List, downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import NumbersIcon from "@mui/icons-material/Numbers";
import { utils, writeFileXLSX } from "xlsx";

const exporter = ({data, locations}) => {
  const dataForExport = data?.map((record) => {
    return {
      id: record?.id,
      locationCode : record?.locationCode,
      location : locations && locations?.length>0 ?  locations?.find(x=> x.locationCode == record?.locationCode)?.name : record?.locationCode,
      boxNumber: record?.boxNumber,
      lockerNumber: record?.lockerNumber,
      conversationId: record?.conversationId,
      duration: durations.filter((x) => x.value == record?.durationId)[0]?.name,
      startDate: moment(record?.startDate).format("DD.MM.YYYY HH:mm"),
      endDate: moment(record?.endDate).format("DD.MM.YYYY HH:mm"),
      nameSurName:   record?.nameSurName ,
      gsmNumber: record?.gsmNumber ,
      email:  record?.email ,
      operationId: record?.operationId,
      orderType: record?.orderType,
      otpCode: record?.otpCode,
      paymentId: record?.paymentId,
      price: record?.price,
      verificationCode: record?.verificationCode,
      
    };
  });

  const ws = utils.json_to_sheet(dataForExport);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Worksheet1");
  writeFileXLSX(wb, `Transactions-${moment().format("yyyMMDDHHmmss")}.xlsx`);

  // console.log(dataForExport);

  // jsonExport(
  //   dataForExport,
  //   {
  //     headers: [
  //       "id",
  //       "boxNumber",
  //       "conversationId",
  //       "durationId",
  //       "endDate",
  //       "gsmNumber",
  //       "insertDate",
  //       "operationId",
  //       "orderType",
  //       "otpCode",
  //       "paymentId",
  //       "price",
  //       "startDate",
  //       "verificationCode",
  //     ],
  //   },
  //   (err, csv) => {
  //     if (err) return console.error(err);
  //     const bom = "\uFEFF";
  //     downloadCSV(
  //       `${bom} ${csv}`,
  //       `Transactions-${moment().format("yyyMMDDHHmmss")}`
  //     );
  //   }
  // );
};

const MyList = ({ children, actions, filters, title, ...props }) => (
  <React.Fragment {...props}>
    <div style={{ marginTop: "0em" }}>
      <Title title={title} />
      <ListToolbar filters={filters} actions={actions} />
      <Card>{children}</Card>
      <Pagination />
    </div>
  </React.Fragment>
);

const ListActions = ({data, locations}) => {
  

  return (
    <TopToolbar>
      <Button onClick={() => exporter({data: data, locations})} label="Export" />
    </TopToolbar>
  );
};

const postFilters = (listContext) => {
  const { setFilters } = listContext;

  console.log(setFilters);

  return [
    <TextInput
      label="Search"
      source="q"
      alwaysOn
      onChange={(v) => {
        setFilters({ q: v });
      }}
    />,
    <TextInput
      label="Gsm Number"
      source="gsmNumber"
      alwaysOn
      placeholder="5XX XXX XX XX"
    />,
  ];
};

const Transactions = () => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState([]);
  const [locations, setLocations] = React.useState(false);




  useEffect(() => {
    if (data?.length == 0)
      dataProvider.BoxList().then(({ data }) => {

        let newData = data;

        if(localStorage.getItem('username') == "natavega")
        newData = newData.filter(x=> x.locationCode == "AA104")
        setData(newData);
      });


      (async () => {

        const res = await dataProvider.getLocations();;


        setLocations(
          res?.data
        );


      })()


  }, []);

  const listContext = useList({ data });


  return data.length == 0 ? (
    <Loading
      loadingPrimary="Loading"
      loadingSecondary="Transaction data is loading, just a moment please..."
    />
  ) : (
    <MyList
      // filters={postFilters(listContext)}
      actions={<ListActions data={data} locations={locations} />}
      title="Transactions"
    >
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="operationId" label="OperationId" />
          <TextField label="Location Code" source="locationCode" />

          

          <FunctionField
            render={(item) =>
              locations && locations?.length>0 ? locations?.find(x=> x.locationCode == item?.locationCode)?.name : item?.locationCode
            }
            source="endDate"
            label="Location"
            textAlign="center"
          />


          <TextField label="Box Number" source="boxNumber" />
          <ChipField
            source="lockerNumber"
            color="error"
            icon={<NumbersIcon />}
          />

          <FunctionField
            render={(item) =>
              moment(item.startDate).format("DD.MM.YYYY [\r\n] HH:mm")
            }
            source="startDate"
            label="Start Date"
            textAlign="center"
          />

          <FunctionField
            render={(item) =>
              moment(item.endDate).format("DD.MM.YYYY [\r\n] HH:mm")
            }
            source="endDate"
            label="End Date"
            textAlign="center"
          />

{localStorage.getItem('username') == "superadmin" &&
       
   
        <FunctionField
            render={(item) => {
       
              return localStorage.getItem('username') == "superadmin" ? item?.nameSurName : parseGsm(item?.nameSurName)}
            }
            source="nameSurname"
            textAlign="center"
          />
        }
          <FunctionField
            render={(item) =>  item?.gsmNumber ? (
              <Link to={"/sendsms?a=1&gsmNumbers=" +  btoa(item.gsmNumber)}>
                {localStorage.getItem('username') == "superadmin" ? item?.gsmNumber : parseGsm(item?.gsmNumber) }
              </Link>
            ) : (<span>{localStorage.getItem('username') == "superadmin" ? item?.email : parseMail(item?.email)}</span>)}
            source="gsmNumber"
            textAlign="center"
            label="Gsm / Email"
          />

          <FunctionField
            render={(item) => (item?.orderType == 2 ? "EXTEND" : "NEW")}
            source="orderType"
            label="Order Type"
            textAlign="center"
          />

          {/* <TextField source="orderType" /> */}

          <FunctionField
            render={(item) =>
              durations.filter((x) => x.value == item.durationId)[0]?.name
            }
            label="Duration"
            source="durationId"
            textAlign="center"
          />
          <FunctionField
            style={{ fontWeight: "bold" }}
            render={(item: any) => paymentTypes[item?.paymentType]}
            source="Payment Type"
            textAlign="center"
          />
          <FunctionField
            style={{ fontWeight: "bold" }}
            render={(item) => (
              <Chip label={item.price + (item?.id > 1236 ? " ₺" : " ₺")} />
            )}
            source="price"
            textAlign="center"
          />
          <TextField
            source="organisation"
            style={{ textTransform: "uppercase" }}
          />

          <ChipField
            icon={<LockOpenIcon />}
            source="otpCode"
            label="Locker Password"
          />

          <TextField source="verificationCode" />
        </Datagrid>
      </ListContextProvider>
    </MyList>
  );
};

export default Transactions;

const durations = [
  {
    name: "1 Week",
    value: 8,
  },
  {
    name: "4 Hours",
    value: 2,
  },
  {
    name: "2 Hours",
    value: 1,
  },
  {
    name: "2 Days",
    value: 7,
  },
  {
    name: "1 Day",
    value: 6,
  },
  {
    name: "6 Hours",
    value: 3,
  },
  {
    name: "1 Hour",
    value: 0,
  },
  {
    name: "1 Month",
    value: 9,
  },
  {
    name: "12 Hours",
    value: 4,
  },
  {
    name: "18 Hours",
    value: 5,
  },
];

const paymentTypes = [
  "",
  "Credit Card",
  "Cash",
  "Promotion",
  "Credit Card (POS)",
];


function parseGsm(gsm){
  if(!gsm) return "";
  return gsm?.substring(0, 5) + "XXX" + gsm?.substring(gsm?.length - 4)
}

function parseMail(mail){

  if (!mail) return "";
  let name = mail?.indexOf("@")>0 ? mail?.split("@")[0] : mail;
  name = name?.substring(0, 5) + "XXX" + name?.substring(name?.length - 4);
  const uzanti = mail?.indexOf("@")>0 ? mail?.split("@")[1] : "";

  return name + "@" + uzanti;
}