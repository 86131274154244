import React, { useEffect, useState } from "react";
import {
  Title, Create,
  Form,
  TextInput,
  SaveButton,
  useEditContext,
  required,
  useDataProvider,
  Button,
  useNotify,
} from "react-admin";
import { Grid, Card, Typography, CardContent } from "@mui/material";
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';


import { deepOrange, deepPurple } from '@mui/material/colors';

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import InventoryIcon from "@mui/icons-material/Inventory";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import { useAppDataProvider } from "../dataProvider";
import { CircularProgress, Avatar } from "@mui/material";
import { useTranslate } from "react-admin";
import moment from "moment";
import { getParsedCommandLineOfConfigFile } from "typescript";

const defaultDurations = [
  {
    status: true,
    "name": "1 Hour",
    "value": 0,
    time: 1
  },
  {
    status: true,
    "name": "2 Hours",
    "value": 1,
    time: 2
  },
  {
    status: true,
    "name": "4 Hours",
    "value": 2,
    time: 4
  },
  {
    status: true,
    "name": "6 Hours",
    "value": 3,
    time: 6
  },
  {
    status: true,
    "name": "12 Hours",
    "value": 4,
    time: 12
  },
  {
    status: true,
    "name": "18 Hours",
    "value": 5,
    time: 18
  }
];

const Locations = (props: any) => {
  const [locationId, setLocationID] = React.useState<any>(false);

  const { locationCode }: any = "null";
  const [kutuId, setKutuId] = React.useState<any>("");
  const [locations, setLocations] = React.useState<any>(false);
  const dp = useDataProvider();

  const notify = useNotify();
  const [expanded, setExpanded] = React.useState<string | false>(false);



  React.useEffect(() => {
    (async () => {
      const res: any = await dp.getLocations();
      if (res) {

        if (
          locationCode &&
          res.data?.filter((k: any) => k?.locationCode == locationCode).length > 0
        ) {

          let newData = res.data.filter((t: any) => t?.locationCode == locationCode)


          if (localStorage.getItem('username') == "natavega")
            newData = newData.filter((x: any) => x.locationCode == "AA104")

          setLocations(
            newData
          );
          setLocationID(locationCode);
          // props.setLocationCode(locationCode);
        } else {
          let newData = res.data;
          if (localStorage.getItem('username') == "natavega")
            newData = newData.filter((x: any) => x.locationCode == "AA104")

          setLocations(newData);
        }
      }
    })();

  }, [dp]);



  const handleChange =
    (panel: string, location: any, _event: React.SyntheticEvent, isExpanded: boolean) => {

      setExpanded(isExpanded ? panel : false);

      dp.getLocationSetting(location?.locationCode).then((res: any) => {

        if (res?.data == null) {
          setLocationName(location?.name);
          setLocationStatus(false);
          setDurations(defaultDurations);

          setassignedLocation(location?.assignedLocation);
          setstreet(location?.street);
          setneighbour(location?.neighbour);
          setregion(location?.region);
          setcity(location?.city);
          setcountry(location?.country);
          setaddress(location?.address);
          settaxNo(location?.taxNo);
          settaxAdministration(location?.taxAdministration);
          setextendPerHour(location?.extendPerHour)

        } else {
          setLocationName(res?.data?.locationName);
          setLocationStatus(res?.data?.locationStatus);
          setDurations(JSON.parse(res?.data?.durations));

          setassignedLocation(res?.data?.assignedLocation);
          setstreet(res?.data?.street);
          setneighbour(res?.data?.neighbour);
          setregion(res?.data?.region);
          setcity(res?.data?.city);
          setcountry(res?.data?.country);
          setaddress(res?.data?.address);
          settaxNo(res?.data?.taxNo);
          settaxAdministration(res?.data?.taxAdministration);
          setextendPerHour(res?.data?.extendPerHour);

        }


      });

    };

  const kutuIdHandle = (v: string) => {
    setKutuId(v);
  };

  let lastLocation = "";


  const [durations, setDurations] = useState<any>(defaultDurations);

  const priceHandleChange = (time: any, price: any) => {

    const duration = durations.find((x: any) => x?.time == time);
    duration.price = price;
    setDurations([...durations.filter((x: any) => x?.time != time), duration])

  }

  const statusHandleChange = (time: any, checked: any) => {

    const duration = durations.find((x: any) => x?.time == time);
    duration.status = checked;
    setDurations([...durations.filter((x: any) => x?.time != time), duration])

  }

  const [locationName, setLocationName] = useState("");
  const [street, setstreet] = useState("");
  const [assignedLocation, setassignedLocation] = useState("");

  const [extendPerHour, setextendPerHour] = useState("");

  


  const [neighbour, setneighbour] = useState("");

  const [region, setregion] = useState("");

  const [city, setcity] = useState("");

  const [country, setcountry] = useState("");

  const [address, setaddress] = useState("");

  const [taxNo, settaxNo] = useState("");

  const [taxAdministration, settaxAdministration] = useState("");

  const [locationStatus, setLocationStatus] = useState(true);

  const handleSave = async (locationCode: any) => {


    await dp.SaveLocationSetting({
      locationName: locationName,
      locationStatus: locationStatus,
      locationCode: locationCode,
      durations: JSON.stringify(durations),
      street : street,
      neighbour : neighbour,
      region : region,
      city : city,
      country : country,
      address : address,
      taxNo : taxNo,
      taxAdministration : taxAdministration,
      extendPerHour : extendPerHour,
      assignedLocation : assignedLocation

    }).then((res: any) => {

      notify(`Price save successfully`, { type: "success", undoable: false });
      window.location.reload();
    });


  }

  return (
    <div style={{ marginTop: "1em" }}>
      {!locations && (
        <div
          style={{
            width: "50px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {locations?.length == 0 && <span>Lokasyon Bulunamadı</span>}

      {locations &&
        (!locationId || locations.length > 1) &&
        locations?.sort(function (a: any, b: any) {
          if (a.locationCode < b.locationCode) {
            return -1;
          }
          if (a.locationCode > b.locationCode) {
            return 1;
          }
          return 0;
        })?.map((location: any, index: number) => {

          if (lastLocation == location.locationCode)
            return;

          lastLocation = location.locationCode;

          return (
            <React.Fragment key={"__accor__" + index}>
              {index == 0 && (
                <Grid container spacing={0} style={{ maxWidth: "180px" }}>

                </Grid>
              )}

              <Accordion
                expanded={expanded === "panel" + index}

              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={"panel" + index + "bh-content"}
                  id={"panel" + index + "bh-header"}
                  onClick={(e) => handleChange("panel" + index, location, e, expanded != "panel" + index)}
                >
                  <Typography sx={{ width: "50%", flexShrink: 0 }}>
                    {location?.name}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    {location?.districtName && location?.districtName + ","}{" "}
                    {location?.cityName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    border: "solid 1px #e8e8e8",
                    padding: "1em",
                    margin: "1em",
                  }}
                >
                  <FormControlLabel control={<Switch color="success" checked={locationStatus} onChange={(e) => setLocationStatus(e?.target?.checked)} />} label="Location Status" />

                  <br />
                  <br />
                  <TextField id="filled-basic" onChange={(e) => setLocationName(e?.target?.value)} fullWidth value={locationName} label="Location Name" variant="filled" />


                  {expanded === "panel" + index && (
                    <Form >

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <div style={{ width: "500px", marginTop: "2em" }}>

                           
                            <Grid container spacing={2}>
                              <Grid item xs={3}><br />Extend Price</Grid>
                              <Grid item xs={4} style={{ textAlign: "center" }}>
                              <TextField fullWidth value={extendPerHour} onChange={(e) => setextendPerHour(e?.target?.value)} label="Extend Price (Per Hour)" />
                    
                                </Grid><Grid xs={5}></Grid>

                              {durations?.sort((a: any, b: any) => a.time - b.time)?.map((x: any) => (<React.Fragment>
                                <Grid item xs={1} style={{ paddingTop: "30px" }}> <FormControlLabel control={<Switch onChange={(e) => statusHandleChange(x?.time, e?.target?.checked)} checked={x?.status} />} label="" />
                                </Grid>
                                <Grid item xs={2} ><div style={{ marginTop: "1.3em", fontWeight: "bold", float: "right" }}>{x?.name}</div></Grid>
                                <Grid item xs={4} ><TextField disabled={!x?.status} value={x?.price || 0} onChange={(e) => priceHandleChange(x?.time, e?.target?.value)} label="" /></Grid><Grid item xs={5}><div style={{ paddingTop: "1.5em" }}>TL</div></Grid>

                              </React.Fragment>))}

                             
                              <Grid item xs={12}>
                                <Button onClick={() => handleSave(location?.locationCode)} variant="contained" color="primary" size="large" disabled={false} label={"SAVE"} /><br /><br /><br />
                              </Grid>
                            </Grid>
                          </div>

                        </Grid>
                        <Grid item xs={6}>
                        <TextField fullWidth value={assignedLocation} onChange={(e) => setassignedLocation(e?.target?.value)} label="Assigned Location" />
                          <TextField fullWidth value={street} onChange={(e) => setstreet(e?.target?.value)} label="Street" />
                          <TextField fullWidth value={neighbour} onChange={(e) => setneighbour(e?.target?.value)} label="Neighbour" />
                          <TextField fullWidth value={region} onChange={(e) => setregion(e?.target?.value)} label="Region" />
                          <TextField fullWidth value={city} onChange={(e) => setcity(e?.target?.value)} label="City" />
                          <TextField fullWidth value={country} onChange={(e) => setcountry(e?.target?.value)} label="Country" />
                          <TextField fullWidth value={address} onChange={(e) => setaddress(e?.target?.value)} label="Address" />
                          <TextField fullWidth value={taxNo} onChange={(e) => settaxNo(e?.target?.value)} label="Tax Number" />
                          <TextField fullWidth value={taxAdministration} onChange={(e) => settaxAdministration(e?.target?.value)} label="Tax Administration" />
                        
                        </Grid>
                      </Grid>

                    </Form>
                    // <LocationDetail
                    //   kutuId={kutuId}
                    //   kutuIdHandle={kutuIdHandle}
                    //   location={location}

                    //   dp={dp}
                    //   {...props}

                    // />

                  )}
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          );
        })}



    </div>
  );
};


const getKutuType = (typeCode: string) => {
  const box = boxes?.find((x) => x.key == typeCode);
  if (box) return box.value;
  return typeCode;
};

const boxes = [
  { key: "S", value: "Small" },
  { key: "M", value: "Medium" },
  { key: "L", value: "Large" },
  { key: "XL", value: "X Large" },
];



function parseMail(mail: any) {

  if (!mail) return "";
  let name = mail?.indexOf("@") > 0 ? mail?.split("@")[0] : mail;
  name = name?.substring(0, 5) + "XXX" + name?.substring(name?.length - 4);
  const uzanti = mail?.indexOf("@") > 0 ? mail?.split("@")[1] : "";

  return name + "@" + uzanti;
}

export default Locations;
