import React from "react";
import NumberFormat, { InputAttributes } from "react-number-format";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TemporaryDrawer from "./TemporaryDrawer";
import InputAdornment from "@mui/material/InputAdornment";

import KeyboardAltIcon from "@mui/icons-material/KeyboardAlt";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

import AlertTitle from "@mui/material/AlertTitle";
import { useTranslate, useLocaleState, useDataProvider } from "react-admin";

import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { MuiTelInput } from "mui-tel-input";
// import { useAppDataProvider } from "../dataProvider";

import ControlledAccordions from "./ControlledAccordions.tsx";
import { FormHelperText } from "@mui/material";
import NumbersIcon from "@mui/icons-material/Numbers";
import { AnyNaptrRecord } from "dns";

const HorizontalLinearStepper = (props: any): any => {

  const [locationCode, setLocation] = React.useState("");

  const [activeStep, setActiveStep] = React.useState(0);
  const [showAlert, setShowAlert] = React.useState(0);
  const [organisation, setOrganisation] = React.useState("standard");


  const organisationHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganisation((event.target as HTMLInputElement).value);
  };



  const [terms, setTerms] = React.useState(true);
  const [paymentType, setPaymentType] = React.useState("1");
  const [termsError, setTermsError] = React.useState(false);
  const [isRental, setIsRental] = React.useState(true);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [sessionID, setSessionID] = React.useState(0);
  const [paymentResult, setPaymentResult] = React.useState({
    otpCode: "",
    status: "",
    boxNumber: "",
    message: "",
    endDate: "",
    lockerNumber: "",
  });
  const [locale, setLocale] = useLocaleState();
  const [cardName, setCardName] = React.useState("");
  const [cardNumber, setCardNumber] = React.useState("");
  const [cardDate, setCardDate] = React.useState("");
  const [cardCvv, setCardCvv] = React.useState("");


  const setLocationCode = (code: any) => {
    setLocation(code);

    (async () => {
      if (code) {
        let res = await dp.getDurations(code);
        if (res) {
          setDurations(res.data.durations);
        }
      }
    })();
  };

  const [buttonLoading, setButtonLoading] = React.useState<any>(false);

  const [cardNameError, setCardNameError] = React.useState(false);
  const [cardNumberError, setCardNumberError] = React.useState(false);
  const [cardDateError, setCardDateError] = React.useState(false);
  const [cardCvvError, setCardCvvError] = React.useState(false);

  const [paymentMessage, setPaymentMessage] = React.useState("");

  const [verification, setVerification] = React.useState<any>("");
  const [verificationMessage, setVerificationMessage] =
    React.useState<any>("Required");
  const [verificationCode, setVerificationCode] = React.useState<any>("");

  const [phoneNumberValidation, setPhoneNumberValidation] =
    React.useState(false);

  const [boxNumberError, setBoxNumberError] = React.useState(false);

  const [durationId, setDurationId] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(10);
  const [durations, setDurations] = React.useState<any>([]);

  const [boxNumber, setBoxNumber] = React.useState("");
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const [inpuType, setInpuType] = React.useState<any>({ inputType: "select" });
  const dp = useDataProvider();

  const [eur, setEur] = React.useState<any>();


  React.useEffect(() => {
    (async () => {
      await dp.getEur().then((res: any) => {
        // setEur(res?.data);
        setEur(1);
      });
    })();
  }, [dp])


  const translate = useTranslate();

  const steps = [
    "Select Locker",
    "Choice Time",
    "Pay for Locker",
    "Get Locker Pasword",
  ];

  // "Select Box", "Choice Time", "Pay for Box", "Get Box Pasword"

  React.useEffect(() => {
    setPaymentType("1");
    setTermsError(false);
    setActiveStep(0);
    setShowAlert(0);
    setPhoneNumber("");
    setSessionID(0);
    setPaymentResult({
      otpCode: "",
      status: "",
      boxNumber: "",
      message: "",
      endDate: "",
      lockerNumber: "",
    });
    setCardName("");
    setCardNumber("");
    setCardDate("");
    setCardCvv("");

    setCardNameError(false);
    setCardNumberError(false);
    setCardDateError(false);
    setCardCvvError(false);

    setPaymentMessage("");
    setVerification("");
    setVerificationMessage("Required");
    setVerificationCode("");
    setPhoneNumberValidation(false);
    setDurationId(0);
    setTotalPrice(10);

    setBoxNumberError(false);
    setBoxNumber("");
    setInpuType({ inputType: props.inputType });
    // console.clear();
  }, [props?.inputType]);

  // React.useEffect(() => {
  //   (async () => {
  //     let res = await dp.getDurations();
  //     if (res) {
  //       setDurations(res.data.durations);
  //     }
  //   })();
  // }, [dp]);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async (tempBoxNumber: any = null) => {
    let newBoxNumber = boxNumber;

    if (activeStep == 0) {
      if (!terms) {
        setTermsError(true);
        setButtonLoading(false);
        return;
      }

      if (boxNumber == "" && !tempBoxNumber) {
        setBoxNumberError(true);

        setButtonLoading(false);
        return;
      }

      if (tempBoxNumber?.length == 6) {
        newBoxNumber = tempBoxNumber;
      }

      if (newBoxNumber == "") {
        setBoxNumberError(true);
        setButtonLoading(false);
        return;
      }

      setButtonLoading(true);
      setShowAlert(1);
      let res = await dp.checkBox(newBoxNumber, locationCode, organisation);
      if (res) {
        setButtonLoading(false);
        if (!res.data?.status) {
          setShowAlert(2);
          return;
        } else {
          setSessionID(res.data?.sessionID);
          if (!res.data?.lockerData?.isAvailable) {
            setShowAlert(3);
            return;
          }
        }
      }
    }

    let newSkipped = skipped;

    if (
      activeStep == 1 &&
      (phoneNumber == "" || phoneNumber.toString().length < 9)
    ) {
      setPhoneNumberValidation(true);
      setButtonLoading(false);
      return;
    }

    if (activeStep == 1 && verificationCode == "") {
      sendOTPCode();
      setButtonLoading(false);
      return;
    }

    if (activeStep == 1 && verificationCode != "") {
      const resData = await dp.verificationLogin({
        sessionID: sessionID,
        verificationCode: verificationCode,
        phoneNumber: phoneNumber.replaceAll(" ", "").trim(),
      });
      if (resData && resData.data) {
        setButtonLoading(false);
        if (!resData?.data?.status === true) {
          setVerification(2);
          setVerificationMessage(resData?.data?.message);
          return;
        }
      } else {
        return;
      }
    }

    if (activeStep == 2) {
      if (paymentType == "1")
        if (
          cardName == "" ||
          cardNumber == "" ||
          cardNumber == "____ ____ ____ ____" ||
          cardDate == "" ||
          cardDate == "MM/YY" ||
          cardCvv == "" ||
          cardCvv == "___"
        ) {
          if (cardName == "") {
            setCardNameError(true);
          }

          if (cardNumber == "" || cardNumber == "____ ____ ____ ____") {
            setCardNumberError(true);
          }

          if (cardDate == "" || cardDate == "MM/YY") {
            setCardDateError(true);
          }

          if (cardCvv == "" || cardCvv == "___") {
            setCardCvvError(true);
          }
          setButtonLoading(false);
          return;
        }

      setButtonLoading(true);

      
      let newPrice : any = 0;
      if (locationCode == "AA103") {
        newPrice =durationId ? ((prices?.filter((x: any) => x.id == calcDuration(durationId))[0]?.price)?.toFixed(2)) : (prices?.length > 0 ? prices[0].price : 0).toFixed(2);
        newPrice = newPrice * 2;
      } else {
        newPrice =durationId ? ((prices?.filter((x: any) => x.id == calcDuration(durationId))[0]?.price)?.toFixed(2)) : (prices?.length > 0 ? prices[0].price : 0).toFixed(2);
      }



      const resData = await dp.payment({
        sessionID: sessionID,
        cardName: cardName,
        cardNumber: cardNumber,
        cardDate: cardDate,
        cardCvv: cardCvv,
        durationId: durationId,
        // price: ((durationId == 0 ? 0.5 : durationId) * 10).toFixed(2),
        price: newPrice + "",
        paymentType: paymentType,
        locationCode: locationCode,
      });
      if (resData && resData.data) {
        if (resData?.data?.status === true) {
          if (paymentType == "1") {
            document.body.innerHTML = resData?.data?.htmlContent;

            setTimeout(() => {
              const form: any = document.getElementsByTagName("form")[0];
              form.submit();
            }, 500);
          } else {
            setButtonLoading(false);
            setPaymentResult(resData?.data);
          }
        } else {
          setButtonLoading(false);
          setPaymentMessage(resData?.data?.message);
          return;
        }
      } else {
        setPaymentMessage("Unexpected error, please try again.");
        setButtonLoading(false);
        return;
      }
    }

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const sendOTPCode = async () => {
    setVerification(1);
    await dp.sendOTPCode({
      phoneNumber: phoneNumber.replaceAll(" ", "").trim(),
      sessionID: sessionID,
      isRental: false,
    });
  };

  const sendOTPCode2 = async () => {
    setVerification(1);
    setVerificationCode("445566");
    setIsRental(true);
    await dp.sendOTPCode({
      phoneNumber: phoneNumber.replaceAll(" ", "").trim(),
      sessionID: sessionID,
      isRental: true,
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const jumpBoxNumber = (qrData: string) => {
    handleNext(qrData);
    setBoxNumber(qrData);
    // setInpuType(qrData);
    setBoxNumberError(false);
    setTimeout(() => {
      setButtonLoading("1");
    }, 100);
  };




  const [prices, setPrices] = React.useState<any>([]);


  React.useEffect(() => {
    (async () => {
      await dp.getBoxPrice().then((res: any) => {

        setPrices(res?.data?.data?.sort((a: any, b: any) => a.price - b.price)?.map((x: any) => {
          if (locationCode == "AA103")
            return { id: x.time, type: x.type, price: (parseFloat(x.price) * 2) }
          return { id: x.time, type: x.type, price: x.price }
        }));
      })

    })();
  }, [dp])



  const calcDuration = (durationId: any) => {
    if (durationId == 0) {
      durationId = 1;
    } else if (durationId == 1) {
      durationId = 2;
    } else if (durationId == 2) {
      durationId = 4;
    } else if (durationId == 3) {
      durationId = 6;
    }
    return durationId;
  }


  return (
    <Box sx={{ width: "100%" }} style={{ paddingRight: "1em" }}>

      {(activeStep == 0 && localStorage.getItem('username') != "natavega")  && (
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Organisation</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={organisation}
            onChange={(e) => organisationHandleChange(e)}
          >
            <FormControlLabel
              value={"standard"}
              control={<Radio />}
              label="Standard"
            />
            <FormControlLabel
              value={"kralsakir"}
              control={<Radio />}
              label="KralSakir"
            />
          </RadioGroup>
        </FormControl>
      )}

      <br />
      <br />
      <br />
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

          {activeStep == 0 && (
            <div style={{ padding: "0", paddingTop: "1em" }}>
              {inpuType?.inputType === "select" && (
                <React.Fragment>
                  <ControlledAccordions
                    jumpBoxNumber={jumpBoxNumber}
                    setLocationCode={setLocationCode}
                  />

                  {showAlert == 2 && (
                    <Alert severity="error">
                      "Locker is not available. please choose a different
                      locker."
                    </Alert>
                  )}
                  {showAlert == 3 && (
                    <Alert severity="error">
                      "The Locker is not available. please choose a different
                      locker."
                    </Alert>
                  )}
                </React.Fragment>
              )}

              {inpuType?.inputType === "text" && (
                <React.Fragment>
                  <TextField
                    style={{ marginBottom: "1em" }}
                    id="filled-basic"
                    label="Enter Locker Number"
                    variant="filled"
                    type="number"
                    error={boxNumberError}
                    fullWidth
                    value={boxNumber}
                    onChange={(e) => {
                      setBoxNumber(e?.target?.value);
                      setBoxNumberError(false);
                    }}
                    InputProps={{
                      placeholder: "Locker Number",
                      startAdornment: (
                        <InputAdornment position="start">
                          <NumbersIcon fontSize="small" color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </React.Fragment>
              )}
              {inpuType?.inputType === "qr" && (
                <div
                  style={{
                    width: "300px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "-1em",
                  }}
                >
                  <CamQrReader JumpBoxNumber={jumpBoxNumber} />
                </div>
              )}

              <div
                style={{
                  width: "50px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {showAlert == 1 && <CircularProgress />}
              </div>

              {/* <Grid item xs={12} style={{ marginTop: "2em" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="saveAddress"
                      defaultChecked={terms}
                      value="yes"
                      onClick={(e) => {
                        setTerms(!terms);
                        setTermsError(false);
                      }}
                    />
                  }
                  label={<TemporaryDrawer />}
                />
                {termsError && (
                  <FormHelperText error={true}>
                    "Required"
                  </FormHelperText>
                )}
              </Grid> */}
            </div>
          )}

          {activeStep == 1 && (
            <div style={{ marginTop: "1em" }}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <MuiTelInput
                    variant="filled"
                    required
                    fullWidth
                    id="gsmNumber"
                    label="GSM Number"
                    forceCallingCode
                    preferredCountries={["TR", "FR"]}
                    error={phoneNumberValidation}
                    InputProps={{
                      // inputComponent: NumberFormatCustom as any,
                      placeholder: "+XX XXX XXX XX XX",
                    }}
                    value={phoneNumber}
                    langOfCountryName="TR"
                    defaultCountry="TR"
                    onChange={(e: any) => {
                      setPhoneNumber(e);
                      setPhoneNumberValidation(false);
                    }}
                  />
                  {/* <TextField
                    value={phoneNumber}
                    error={phoneNumberValidation}
                    fullWidth={true}
                    label="Gsm Number"
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setPhoneNumberValidation(false);
                    }}
                    required
                    id="gsmNumber"
                    variant="filled"
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                      placeholder: "+XX XXX XXX XX XX",
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIphoneIcon fontSize="small" color="action" />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                </Grid>
                <Grid item xs={4}>
                  <Button
                    color="primary"
                    style={{ marginTop: "15px" }}
                    variant="contained"
                    onClick={() => sendOTPCode()}
                    disabled={
                      phoneNumber == "" ||
                      phoneNumber.toString().length < 9 ||
                      verification != 0
                    }
                  >
                    Send Code
                  </Button>

                  <Button
                    color="success"
                    style={{ marginTop: "15px", marginLeft: "1em" }}
                    variant="contained"
                    onClick={() => sendOTPCode2()}
                    disabled={
                      phoneNumber == "" ||
                      phoneNumber.toString().length < 9 ||
                      verification != 0
                    }
                  >
                    Skip Verification
                  </Button>
                </Grid>

                {verification != 0 && (
                  <Grid item xs={12}>
                    {" "}
                    <div
                      style={{
                        display: verificationCode === "445566" ? "none" : "",
                      }}
                    >
                      <TextField
                        required
                        value={verificationCode}
                        onChange={(e) => {
                          setVerificationCode(e.target.value);
                          setVerification(1);
                        }}
                        id="verificationCode"
                        label="Verification Code"
                        error={verification == 2}
                        variant="filled"
                        InputProps={{
                          inputComponent: NumberFormatVerification as any,
                          placeholder: "XXXXXX",
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIphoneIcon
                                fontSize="small"
                                color="action"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />

                      {verification == 2 && (
                        <Alert severity="error">
                          Please check your GSM Number or VerificationCode
                        </Alert>
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>
              <PriceBox
                translate={translate}
                durationId={durationId}
                setDurationId={setDurationId}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                durations={durations}
                locationCode={locationCode}
              />
            </div>
          )}

          {activeStep == 2 && (
            <div style={{ marginTop: "1em" }}>
              <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Payment Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="1"
                        name="radio-buttons-group"
                        onChange={(e, v) => setPaymentType(v + "")}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Online Credit Card"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Cash"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="Promotion"
                        />
                        <FormControlLabel
                          value="4"
                          control={<Radio />}
                          label="Credit Card (POS)"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                {paymentType == "1" && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="cardName"
                        label="Name on card"
                        error={cardNameError}
                        value={cardName}
                        onChange={(e) => {
                          setCardName(e?.target?.value);
                          setCardNameError(false);
                        }}
                        fullWidth
                        autoComplete="cc-name"
                        variant="standard"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="cardNumber"
                        label="Card Number"
                        value={cardNumber}
                        error={cardNumberError}
                        onChange={(e) => {
                          setCardNumber(e?.target?.value);
                          setCardNumberError(false);
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCredit as any,
                        }}
                        fullWidth
                        autoComplete="cc-number"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="expDate"
                        label="Expiry Date (MM/YY)"
                        value={cardDate}
                        error={cardDateError}
                        onChange={(e) => {
                          setCardDate(e?.target?.value);
                          setCardDateError(false);
                        }}
                        fullWidth
                        InputProps={{
                          inputComponent: NumberFormatCCDate as any,
                        }}
                        autoComplete="cc-exp"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="cvv"
                        label="CVV"
                        value={cardCvv}
                        error={cardCvvError}
                        onChange={(e) => {
                          setCardCvv(e?.target?.value);
                          setCardCvvError(false);
                        }}
                        helperText="Last three digits on signature strip"
                        fullWidth
                        InputProps={{
                          inputComponent: NumberFormatCVV as any,
                        }}
                        autoComplete="cc-csc"
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                )}

                {paymentMessage != "" && (
                  <Grid item xs={12} md={12}>
                    <Alert severity="error">{paymentMessage}</Alert>
                  </Grid>
                )}
                {paymentType != "3" && (
                  <div style={{ display: "flex" }}>
                    <div style={{ marginTop: "2em", marginLeft: "1.5em" }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Total Price
                      </Typography>
                      <div>
                        <Typography
                          variant="button"
                          display="block"
                          style={{ fontSize: "2em", marginTop: "-0.6em" }}
                          gutterBottom
                        >
                          ₺{" "}
                          {/* {(prices?.filter((x: any) => x.id == calcDuration(durationId))[0]?.price?.toFixed(2))} */}
                          {locationCode == "AA103" &&
                            <span>{durationId ? ((parseFloat(prices?.filter((x: any) => x.id == calcDuration(durationId))[0]?.price) * 2).toFixed(2)) : (prices?.length > 0 ? parseFloat(prices[0].price) * 2 : 0).toFixed(2)}</span>
                          }
                          {locationCode != "AA103" &&
                            <span>{durationId ? (prices?.filter((x: any) => x.id == calcDuration(durationId))[0]?.price?.toFixed(2)) : (prices?.length > 0 ? prices[0].price : 0).toFixed(2)}</span>
                          }
                        </Typography>
                      </div>
                      {/* <div style={{ marginTop: "-1em" }}><small> {((eur * prices?.filter((x: any) => x.id == calcDuration(durationId))[0]?.price)?.toFixed(2))} TRY</small></div> */}
                    </div>
                  </div>
                )}
              </React.Fragment>
            </div>
          )}

          {activeStep == 3 && (
            <div style={{ marginTop: "2em" }}>
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #{sessionID}.
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "1em" }}
                >
                  Locker Number : #{paymentResult?.lockerNumber}
                </Typography>

                <Alert severity="success">
                  <AlertTitle>Locker Password</AlertTitle>
                  <Typography variant="h3">{paymentResult?.otpCode}</Typography>
                  <br />
                  <br />
                  Start Date : {moment().format("DD/MM/YYYY HH:mm")}
                  <br />
                  End Date :{" "}
                  {moment(paymentResult?.endDate).format("DD/MM/YYYY HH:mm")}
                  <br />
                  Duration :{" "}
                  {locale == "tr" ? (
                    <span>
                      {durations
                        .filter((x: any) => x?.value == durationId)[0]
                        .name?.replaceAll("Hours", "Saat")
                        ?.replaceAll("Hour", "Saat")
                        ?.replaceAll("Week", "Hafta")
                        ?.replaceAll("Month", "Ay")
                        ?.replaceAll("Days", "Gün")
                        ?.replaceAll("Day", "Gün")}
                    </span>
                  ) : (
                    <span>
                      {
                        durations.filter((x: any) => x?.value == durationId)[0]
                          .name
                      }
                    </span>
                  )}
                </Alert>
              </React.Fragment>
            </div>
          )}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />

            {buttonLoading !== "1" &&
              activeStep !== steps.length - 1 &&
              !(inpuType?.inputType == "select" && activeStep == 0) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleNext}
                  disabled={buttonLoading}
                >
                  {buttonLoading == true ? (
                    <CircularProgress />
                  ) : (
                    <span>Next</span>
                  )}
                </Button>
              )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default HorizontalLinearStepper;

const PriceBox = (props: any) => {
  const [locale, setLocale] = useLocaleState();



  const dp = useDataProvider();
  const [eur, setEur] = React.useState<any>();


  React.useEffect(() => {
    (async () => {
      await dp.getEur().then((res: any) => {
        // setEur(res?.data);
        setEur(1);
      });
    })();
  }, [dp])
  const [prices, setPrices] = React.useState<any>([]);


  React.useEffect(() => {
    (async () => {
      await dp.getBoxPrice().then((res: any) => {

        setPrices(res?.data?.data?.sort((a: any, b: any) => a.price - b.price)?.map((x: any) => {
          if (props?.locationCode == "AA103")
          return { id: x.time, type: x.type, price: (parseFloat(x.price) * 2) }
          return { id: x.time, type: x.type, price: x.price }
        }));
      })

    })();
  }, [dp])


  const calcDuration = (durationId: any) => {
    if (durationId == 0) {
      durationId = 1;
    } else if (durationId == 1) {
      durationId = 2;
    } else if (durationId == 2) {
      durationId = 4;
    } else if (durationId == 3) {
      durationId = 6;
    }
    return durationId;
  }




  return (
    <React.Fragment>
      <FormControl>
        <FormLabel
          style={{ marginTop: "2em" }}
          id="demo-radio-buttons-group-label"
        >
          Duration
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={props.durationId}
          onChange={(e) => {
            props.setDurationId(e.target.value);
          }}
          name="radio-buttons-group"
          row
        >
          {props?.durations.filter((x: any) => x.value < 4)
            ?.sort((a: any, b: any) => a.value - b.value)
            .map((x: any) => (
              <FormControlLabel
                key={"__radio__" + x.value}
                value={x.value}
                control={<Radio />}
                label={
                  locale == "en"
                    ? x.name
                    : x.name
                      .replaceAll("Hours", "Saat")
                      .replaceAll("Hour", "Saat")
                      .replaceAll("Week", "Hafta")
                      .replaceAll("Month", "Ay")
                      .replaceAll("Days", "Gün")
                      .replaceAll("Day", "Gün")
                }
              />
            ))}
        </RadioGroup>
      </FormControl>
      <div style={{ display: "flex" }}>
        <div style={{ marginTop: "2em", float: "right" }}>
          <Typography variant="h6" gutterBottom component="div">
            Total Price
          </Typography>
          <div>
            <Typography
              variant="button"
              display="block"
              style={{ fontSize: "2em", marginTop: "-0.6em" }}
              gutterBottom
            >
              ₺{" "}
              {(prices?.filter((x: any) => x.id == calcDuration(props?.durationId))[0]?.price?.toFixed(2))}
            </Typography>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+## ### ### ## ##"
      mask="_"
    />
  );
});

const NumberFormatCredit = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCredit(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: any) =>
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }
      format="#### #### #### ####"
      mask="_"
      placeholder="XXXX XXXX XXXX XXXX"
    />
  );
});

const NumberFormatCCDate = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCCDate(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: any) =>
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }
      format="##/##"
      placeholder="MM/YY"
      mask={["M", "M", "Y", "Y"]}
    />
  );
});

const NumberFormatCVV = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCVV(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: any) =>
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }
      format="###"
      mask="_"
      placeholder="XXX"
    />
  );
});

const NumberFormatVerification = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatVerification(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: any) =>
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }
      format="######"
      mask="_"
      placeholder="XXXXXX"
    />
  );
});

export const CamQrReader = (props: any) => {
  const [data, setData] = React.useState("No result");

  React.useEffect(() => {
    if (data?.length == 6 && isNumeric(data)) {
      props.JumpBoxNumber(data);
    }
  }, [data, props]);

  return (
    <>
      {/* <QrReader
        constraints={{ facingMode: "environment" }}
        onResult={(result: any, error:any) => {
          if (!!result) {
            setData(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        {...{ style: { width: "100%" } }}
      /> */}
    </>
  );
};

function isNumeric(str: any) {
  return !isNaN(str) && !isNaN(parseFloat(str));
}
